import React, { ReactNode } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { get } from "lodash";
import { FluidObject } from "gatsby-image";
import Img, { GatsbyImageProps } from "gatsby-image"; // to take image data and render it
import "./blog-post.css";
import PostShowPage from "../components/PostShowPage";
import { PostSchema } from "../lib/";
import { DefaultGatsbyImage } from "../components/DefaultGatsbyImage";

const formatQueryData = ({ post }): PostSchema => {
  const imageSrc = get(post.image, "childImageSharp.fluid");
  return {
    ...post,
    image: imageSrc ? (
      <Img
        imgStyle={{ objectFit: post.imageFit }}
        style={{ width: "100%", height: "100%" }}
        fluid={imageSrc}
      />
    ) : (
      <DefaultGatsbyImage
        style={{ width: "100%", height: "100%" }}
        seed={post.title}
        imgType="fluid"
      />
    ),
  };
};

export default ({ data, location }) => (
  <PostShowPage location={location} {...formatQueryData(data)} />
);

export const query = graphql`
  query($slug: String!) {
    post: posts(slug: { eq: $slug }) {
      html
      title
      imageFit
      image {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      date
      slug
    }
  }
`;
