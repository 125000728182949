import React from "react";
import { PostShowPageProps } from "../lib";
import PageLayout from "./PageLayout";

export default ({ html, date, title, image }: PostShowPageProps) => {
  return (
    <PageLayout>
      <article>
        <h1>{title}</h1>
        <h2>Published: {date || "Not Listed"}</h2>
        <div style={{ maxWidth: 600 }}>{image}</div>
        <div className="blog-post-body">
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </div>
      </article>
    </PageLayout>
  );
};
